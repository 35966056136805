body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(to bottom, #000, #111);
  color: #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
