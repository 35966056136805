.coming-soon-container {
  text-align: center;
  padding: 40px 80px;
}

.logo {
  width: 120px;
  margin-bottom: 20px;
}

.gradient-heading {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  background: linear-gradient(to right, #9b51e0, #5c79ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description {
  font-size: 1.2rem;
  color: #ccc;
  margin-bottom: 20px;
}

.waitlist-detail {
  font-size: 1rem;
  color: #aaa;
  margin-bottom: 30px;
  line-height: 1.5;
}

.waitlist {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.email-input {
  width: 300px;
  height: 44px;
  background-color: #222;
  border: 1px solid #444;
  color: #fff;
  padding: 0 12px;
  border-radius: 8px;
  box-shadow: none!important;
}

.email-input::placeholder {
  color: #888;
}

.email-input:focus {
  border-color: #9b51e0;
  outline: none;
  background-color: #333;
}

.email-input:hover {
  border-color: #9b51e0;
}

.join-button {
  height: 44px;
  line-height: 44px;
  padding: 0 20px;
  border-radius: 8px;
  background: linear-gradient(to right, #9b51e0, #5c79ff)!important;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border: none;
  box-shadow: none ;
}

.join-button:hover {
  transform: translateY(-2px);
}

.footer {
  margin-top: 40px;
  font-size: 0.9rem;
  color: #777;
}


@media (max-width: 768px) {
  .gradient-heading {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .waitlist {
    flex-direction: column;
    gap: 15px;
  }

  .email-input,
  .join-button {
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .coming-soon-container {
    text-align: center;
    padding: 40px 40px;
  }
  
}

@media (max-width: 480px) {
  .gradient-heading {
    font-size: 2rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .logo {
    width: 80px;
  }
}
